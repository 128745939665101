<template>
<div :class="'iq-sidebar sidebar-default ' + variant">
    
    <div class="iq-sidebar-logo d-flex align-items-end justify-content-between">
         <a href="#" class="header-logo">
            <img src="@/assets/images/Ayuda.png" class="img-fluid rounded-normal light-logo" alt="logo">
            <img src="@/assets/images/logo-dark.png" class="img-fluid rounded-normal d-none sidebar-light-img" alt="logo">
            <span>AYUDA</span>
        </a>
        <div class="side-menu-bt-sidebar-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
        </div>
    </div>

    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar" v-if="this.$store.getters.usuario.role == 1" >
        <nav class="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" class="side-menu" >

                <li class="px-3 pt-3 pb-2">
                    <span class="text-uppercase small font-weight-bold">MENU</span>
                </li>

<!--
                <li :class="checkActive('Solicitud.Sostenedor') ? 'sidebar-layout active' : 'sidebar-layout'">
                    <router-link :to="{name :'Solicitud.Sostenedor'}" class="svg-icon">
                        <i class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </i>
                        <span class="ml-2">Solicitud-Asigna</span>
                    </router-link>
                </li>
                -->
               
                    <li :class="( checkActive('Solicitudes.Tickets') || checkActive('Ver.solicitud') ) ? 'sidebar-layout active' : 'sidebar-layout'">
                        <router-link :to="{name: 'Solicitudes.Tickets'}" class="svg-icon">
                            <i class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                </svg>
                            </i>
                            <span class="ml-2">Tickets Soporte</span>
                        </router-link>
                    </li>
                

            </ul>
        </nav>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import {core} from '@/config/pluginInit'
export default {
  name:"SidebarStyle",
  props:{
     items: Array,
     variant: {type: String}
  },
  data () {
    return{
      homeurl:'',
      dashboards:['layout.dashboard1','layout.dashboard2','layout.dashboard3','layout.customer','layout.product','layout.order','layout.calendar'],
      app:['app.chat', 'app.todo'],
      charts:['chart.amchart','chart.apexchart','chart.highchart','chart.morrischart',],
      user:['app.user-profile','app.user-add','app.user-list'],
      ui:['Ui.avatars','Ui.alerts','Ui.badges','Ui.breadcrumbs','Ui.buttons','Ui.button-groups','Ui.boxshadows','Ui.colors','Ui.cards','Ui.carousels','Ui.grids','Ui.helper-classes','Ui.images','Ui.list-groups','Ui.media-objects','Ui.modals','Ui.notifications'
      ,'Ui.paginations','Ui.popovers','Ui.progressbars','Ui.typographys','Ui.tabs','Ui.tooltips','Ui.Embed-videos'],
      auth:['auth.login','auth.register','auth.recover-password','auth.confirm-mail','auth.lock-screen'],
      pricing:['price.pay','price.pay2'],
      icon:['icon.dripicon', 'icon.fontawsome','icon.lineawsome','icon.remixicon',],
      error:['error.404','error.500'],
      formControls:['controls.form-checkbox','controls.form-layout','controls.form-input','controls.form-radio','controls.form-switch','controls.form-textarea','controls.form-validation',],
      formWidget:['widget.form-datepicker','widget.form-file-uploader','widget.form-quill',],
      table:['table.basic-table','table.data-table','table.edit-table',],
      timeline:['time.line','time.line1'],
      extraPages:[ 'price.pay','pages.maintenance','pages.comingsoon','pages.invoices','pages.subscribers','pages.faq','pages.blank-page','pages.icon'],
      pages:[]
    }
  },
  mounted () {
    core.SmoothScrollbar()
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    if (sidebar !== null) {
         this.variant=''
        switch (sidebar) {
            case "0":
                this.variant ='sidebar-dark'
            break;
            case "1":
                this.variant ='sidebar-light'
            break;
            default:
                this.variant =''
                break;
        }
    }
  },
    destroyed () {
        core.SmoothScrollbar()
    },
    computed : {
        ...mapGetters({
            logo:'darklogo',
            sidelogo:'logo',
        })
    },
    methods: {
        checkActive(route) {
            if (this.$route.name == route) {
                return true;
            }
            if (route.includes(this.$route.name)) {
                return true
            }
        },
        miniSidebar(){
            core.triggerSet()
        },
        showCollapse(collapseId) {
            document.getElementById(collapseId).closest('li').classList.add('active')
        },
        hideCollapse() {
            const list = document.querySelector('.iq-sidebar-menu').querySelectorAll('.submenu')
            Array.from(list, elem => {
                if (elem.closest('.active') !== null && elem.closest('.active') !== undefined) {
                    elem.closest('.active').classList.remove('active')
                }
            })
        }
    },
}
</script>

